@import "minima";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
/*
@font-face {
        font-family: 'geomanist';
        src: url('/assets/fonts/Geomanist_Medium/geomanist-medium.eot');
        src: url('/assets/fonts/Geomanist_Medium/geomanist-medium.woff') format('woff'),
        url('/assets/fonts/Geomanist_Medium/geomanist-medium.ttf') format('truetype'),
        url('/assets/fonts/Geomanist_Medium/geomanist-medium.svg#geomanistmedium') format('svg');
        font-weight: 700;
        font-style: normal;
}
*/

@font-face {
        font-family: 'geomanist';
        src: url('/assets/fonts/Geomanist_Book/geomanist-book.eot');
        src: url('/assets/fonts/Geomanist_Book/geomanist-book.woff') format('woff'),
        url('/assets/fonts/Geomanist_Book/geomanist-book.ttf') format('truetype'),
        url('/assets/fonts/Geomanist_Book/geomanist-book.svg#geomanistbook') format('svg');
        font-weight: 500;
        font-style: normal;
}

@font-face {
        font-family: 'geomanist';
        src: url('/assets/fonts/Geomanist_Regular/geomanist-regular.eot');
        src: url('/assets/fonts/Geomanist_Regular/geomanist-regular.woff') format('woff'),
        url('/assets/fonts/Geomanist_Regular/geomanist-regular.ttf') format('truetype'),
        url('/assets/fonts/Geomanist_Regular/geomanist-regular.svg#geomanist_regularregular') format('svg');
        font-weight: 400;
        font-style: normal;
}

/* ----- Utility Classes etc ----- */
.hidden {
  display: none;
}

.muted {
  color: #AAAAAA;
}

.infobox {
  border: 1px solid #ccc;
  padding: 12px 16px 10px 16px;
  background-color: #eee;
}

.section.call-to-action {
  margin-top: 26px;
}

.caption {
  /*font-style: italic;*/
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  margin-top: 6px;
}

img.auto-width {
  width: 100%; 
  height: auto;
}

img.framed {
  border: 2px solid #eee;
}

body {
  font-family: 'Geomanist', 'Arial', sans-serif;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header.site-header {
  background-color: #0074D9;
  border-top: none;

  .wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .site-title {
    color: white;
    background-color: #000000;
    padding: 2px 22px 0 22px;
    line-height: 50px;
    font-size: 22px;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      background-color: white;
      color: #000000;
    }
  }

  nav.site-nav a {
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      font-weight: 300;
  }

}

@include media-query($on-palm) {
  header.site-header nav.site-nav a {
    color: #000000;
  }
}

body main, .page-content {
  padding-top: 40px; 
}

a, a:link {
  color: #0074D9;
}

a.btn {
  &.primary {
    background-color: #0074D9;
  }
  background-color: #aaa;
  color: white;
  padding: 10px;
}

.post-content, p {
  color: #5d5d5d;
}

.post-content {
  ul li {
    margin-bottom: 6px;
  }

  iframe {
    display: block;
    margin-top: 12px;
  }
}

.post-header {
  .post-title {
    font-weight: 500;
  }
}

p {
  line-height: 1.6;
}

p.hero {
  font-size: 26px;
  color: #000000;
  font-weight: 500; 
}

p.emphasis {
  color: #000000;
  font-size: 26px;
}

ul.inline {
  margin-left: 0;
}

ul.inline li {
  display:inline-block;
  padding-right: 20px;
  margin-bottom: 5px;
}

/* From: https://www.youtube.com/watch?v=9YffrCViTVk */
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* ----- Footer ----- */
footer .p-name {
  display: none;
}

footer {
  background-color: #E6E7E8;
}

/* ----- Buttons and stuff ----- */
#ig-links li a, a.ro-btn {
  display: inline-block;
  padding: 10px 30px;
}

#ig-links li a:hover, a.ro-btn:hover {
  text-decoration: none;
  background-color: black;
  color: white;
}

#ig-links li a i.fas, #ig-links li a i.fab, a.ro-btn i.fas, a.ro-btn i.fab {
  margin-right: 6px;
}

a.ro-btn, a:link.ro-btn {
  background-color: #aaa;
  color: white;
}

a.ro-btn.primary, a:link.ro-btn.primary {
  background-color: #0074D9;
}
